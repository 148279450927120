import { BookModel, SearchRequest } from "../Serializers/SerializerModels";
import { fetchPostDataNoAuth } from "../Utilities/FetchUtils";

export const handleSearch = async ({
  searchTerm,
  setBooksDetails,
  setIsLoading,
  setError,
  offset,
  limit,
  setOffset,
  setHasMore,
}) => {
  setIsLoading(true);
  setError(null);
  const { response, _ } = await fetchPostDataNoAuth(
    "books/search",
    new SearchRequest(searchTerm, limit, offset)
  );

  if (response) {
    const bookdetails = response.book_details.map(
      (book) => new BookModel(book)
    );
    if (bookdetails.length === 0) {
      setError(`No books found for ${searchTerm}`);
    }
    setBooksDetails(bookdetails);
    setHasMore(bookdetails.length === limit);
    setOffset(offset + limit);
  } else {
    setError(`No books found for ${searchTerm}`);
  }
  setIsLoading(false);
};


export const loadMore = async ({
  searchTerm,
  setBooksDetails,
  setIsMoreLoading,
  setError,
  offset,
  limit,
  setOffset,
  setHasMore,
}) => {
  setIsMoreLoading(true);
  setError(null);
  const { response, _ } = await fetchPostDataNoAuth(
    "books/search",
    new SearchRequest(searchTerm, limit, offset)
  );

  if (response) {
    const bookdetails = response.book_details.map(
      (book) => new BookModel(book)
    );
    if (bookdetails.length === 0) {
      setError(`No books found for ${searchTerm}`);
    }
    setBooksDetails((books)=>[...books,...bookdetails]);
    setHasMore(bookdetails.length === limit);
    setOffset(offset + limit);
  } else {
    setError(`No books found for ${searchTerm}`);
  }
  setIsMoreLoading(false);
};