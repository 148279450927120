const CancelConfirmModal = ({ setShowCancelConfirmModal, book,cancelOrder }) => {

    const handleBackdropClick = (e) => {
      // Close the modal only if the click is outside the modal container
      if (e.target === e.currentTarget) {
        setShowCancelConfirmModal(false);
      }
    };
  
    return (
      <>
        <div
          className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 animate-fadeIn"
          onClick={handleBackdropClick}
        >
          <div
            className="bg-white rounded-2xl p-8 shadow-2xl w-96 max-w-full transform transition-transform scale-95 hover:scale-100"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-2xl font-bold text-red-600 mb-4 text-center">
              ⚠️ Cancel Rental
            </h2>
            <div className="flex flex-col items-center mb-6">
              <img
                src={book.image_url}
                alt={book.title}
                className="w-32 h-48 object-cover rounded-md mb-4 shadow-md"
              />
              <h3 className="text-xl font-semibold text-customBlue text-center">
                {book.book_name}
              </h3>
            </div>
            <p className="text-gray-700 text-base mb-6 text-center leading-relaxed">
              Are you sure you want to cancel this rental order?
              <br />
              This action cannot be undone.
            </p>
            <div className="flex flex-col gap-4 items-center">
              <button
                onClick={() => setShowCancelConfirmModal(false)}
                className="bg-gray-200 text-gray-700 px-5 py-2 rounded-lg hover:bg-gray-300 transition-all duration-300 ease-in-out transform hover:scale-105"
              >
                No, Keep the Order
              </button>
              <button
                onClick={() => {
                  // Handle the cancellation logic here
                  setShowCancelConfirmModal(false);
                  cancelOrder(book);
                }}
                className="bg-red-600 text-white px-5 py-2 rounded-lg hover:bg-red-700 transition-all duration-300 ease-in-out transform hover:scale-105"
              >
                Yes, Cancel Order
              </button>
            </div>
          </div>
        </div>
  
        {/* Optional fade-in animation */}
        <style jsx>{`
          .animate-fadeIn {
            animation: fadeIn 0.5s ease-out;
          }
  
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        `}</style>
      </>
    );
  };
  

export default CancelConfirmModal 