import React, { useEffect, useState, useContext } from "react";
import MbTitleBar from "../Common/MbTitleBar";
import Logo1 from "../../Assets/logo-1.webp";
import { ReactComponent as Sparkle } from "../../Assets/icons/sparkle_icon.svg";
import { ReactComponent as PaperPlaneRight } from "../../Assets/icons/PaperPlaneRight.svg";
import { getChats, handleDeleteChat } from "./ChatUtils";
import Slider from "react-slick";
import { UserContext } from "../../App"; // Import UserContext
import confused_dog_illustration from "../../Assets/confused-dog.webp";

const MbChatComponent = ({
  chats,
  characterInput,
  showInput,
  setCharacterInput,
  setShowInput,
  handleNewChat,
  handleCharacterSelect,
  setChats,
  setIsLoading,
  isCharacterSelected,
  inputMessage,
  setInputMessage,
  handleSend,
  handleKeyPress,
  messages,
  isMessagesLoading,
  setIsCharacterSelected,
  selectedCharacter,
  selectedShow,
}) => {
  const { isUserLoggedIn } = useContext(UserContext); // Check user login status
  const [startChatClicked, setStartChatClicked] = useState(false); // Track if Start Chat is clicked

  return (
    <>
      <div className="w-full h-full flex flex-col mb-[40px]">
        <MbTitleBar />
        {/* Show sign-in message if Start Chat is clicked and user is not logged in */}
        {!isUserLoggedIn && startChatClicked ? (
          <div className="flex flex-col items-center justify-center h-full w-full p-6">
            <div className="flex flex-col items-center">
            <img
              src={confused_dog_illustration}
              alt="Confused Dog Illustration"
              className="w-1/2"
            />
            <h1 className="text-start mb-4 font-semibold text-2xl text-customBlue">
              Hold on!
            </h1>
            <div className="text-center mb-4">
              You need to sign in to access this.
            </div>

            <div className="flex justify-center w-full">
              <a
                href="/login"
                className="mt-4 bg-customBlue text-white font-bold py-2 px-4 rounded hover:bg-customBlueDark transition duration-200"
              >
                Sign In 
              </a>
            </div>
          </div>
          </div>
        ) : (
          <>
            {!isCharacterSelected ? (
              <MbChatSelect
                chats={chats}
                characterInput={characterInput}
                showInput={showInput}
                setCharacterInput={setCharacterInput}
                setShowInput={setShowInput}
                handleNewChat={handleNewChat}
                handleCharacterSelect={handleCharacterSelect}
                setChats={setChats}
                setIsLoading={setIsLoading}
                setStartChatClicked={setStartChatClicked} // Track start chat click
              />
            ) : (
              <MbChatBox
                messages={messages}
                chats={chats}
                selectedCharacter={selectedCharacter}
                selectedShow={selectedShow}
                setInputMessage={setInputMessage}
                inputMessage={inputMessage}
                handleSend={handleSend}
                isCharacterSelected={isCharacterSelected}
                handleKeyPress={handleKeyPress}
                isLoading={setIsLoading}
                isMessagesLoading={isMessagesLoading}
                setIsCharacterSelected={setIsCharacterSelected}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MbChatComponent;

// Updated MbChatSelect with Start Chat Click Handler
const MbChatSelect = ({
  chats,
  setCharacterInput,
  characterInput,
  setShowInput,
  showInput,
  handleNewChat,
  handleCharacterSelect,
  setChats,
  setIsLoading,
  setStartChatClicked,
}) => {
  const [enterCharacter, setEnterCharacter] = useState(false);

  return (
    <div className="w-full h-full overflow-y-auto">
      <div className="w-full flex justify-center py-[24px]">
        <img src={Logo1} alt="Logo" className="w-[99px] h-[104px]" />
      </div>
      <div className="w-full flex justify-center px-[24px] mb-[24px]">
        <div className="text-lg font-semibold h-[24px]">
          Your Favorite Characters Are Waiting!
        </div>
      </div>

      <div className="w-full flex justify-center px-[22px] mb-[24px]">
        <div className="text-base text-center">
          Ask Sherlock why he always plays the violin or Elizabeth Bennet about
          her first impression of Mr. Darcy.
        </div>
      </div>

      {!enterCharacter ? (
        <div
          className="mx-[23px] text-white font-semibold flex justify-center items-center h-11 bg-customBlue rounded-xl mt-10 cursor-pointer hover:bg-customBlueDark transition-all duration-300"
          onClick={() => {
            setStartChatClicked(true); // Track start chat click
            setEnterCharacter(true);
          }}
        >
          Start Chat
        </div>
      ) : (
        <>
          {/* Input Fields for Book & Character Name */}
          <div className="flex flex-col w-full mt-[16px] px-[22px]">
            <div className="text-sm h-[22px]">
              Book Name <span className="text-red-600">*</span>
            </div>
            <div className="w-full h-[44px] bg-[#F7F9FB] rounded-xl mt-2">
              <input
                type="text"
                placeholder="Enter Book Name"
                className="w-full h-full rounded-2xl p-2 focus:outline-none"
                onChange={(e) => setShowInput(e.target.value)}
                value={showInput}
              />
            </div>
            <div className="text-sm h-[22px] mt-[30px]">
              Character Name <span className="text-red-600">*</span>
            </div>
            <div className="w-full h-[44px] bg-[#F7F9FB] rounded-xl mt-2">
              <input
                type="text"
                placeholder="Enter Character Name"
                className="w-full h-full rounded-2xl p-2 focus:outline-none"
                value={characterInput}
                onChange={(e) => setCharacterInput(e.target.value)}
              />
            </div>

            <div
              className={`text-white font-semibold flex justify-center items-center h-11 rounded-xl mt-10 ${
                showInput === "" || characterInput === ""
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-customBlue cursor-pointer hover:bg-customBlueDark transition-all duration-300"
              }`}
              onClick={() => handleNewChat()}
            >
              <PaperPlaneRight width="20px" height="20px" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const MbChatBox = ({
  messages,
  selectedCharacter,
  selectedShow,
  setInputMessage,
  inputMessage,
  handleSend,
  isCharacterSelected,
  handleKeyPress,
  isLoading,
  isMessagesLoading,
  setIsCharacterSelected,
}) => {
  return (
    <>
      <div className="flex flex-col h-full">
        {/* Top Bar */}
        <div className="h-[52px] mt-[39px] px-[22px] flex flex-row justify-center">
          <div className="w-full flex flex-row">
            <div className="w-1/12 flex justify-center items-center">
              <i
                className="fa fa-arrow-left mr-4 fa-lg cursor-pointer"
                onClick={() => setIsCharacterSelected(false)}
              />
            </div>
            <div className="w-full flex-row flex justify-center">
              <div className="flex -space-x-5">
                <div className="w-[47px] h-[47px] justify-center items-center flex border-2 rounded-full bg-white text-slate-800">
                  <i className="fa-solid fa-user text-2xl"></i>
                </div>
                <div className="w-[47px] h-[47px] justify-center items-center flex border-2 rounded-full bg-customBlue text-2xl text-white">
                  {selectedCharacter[0]}
                </div>
              </div>
              <div className="flex h-full items-center justify-center text-lg font-semibold p-2">
                {selectedCharacter}
              </div>
            </div>
          </div>
        </div>

        {/* Message Box */}
        <div className="flex max-h-[calc(100dvh-300px)] flex-grow flex-col overflow-y-auto px-[22px]">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`w-full mb-[35px] flex ${
                message.role === "user" ? "flex-row-reverse" : "flex-row"
              }`}
            >
              <div className="w-[47px] h-[47px] justify-center items-center flex border-2 rounded-full bg-white text-slate-800">
                {message.role === "user" ? (
                  <i className="fa-solid fa-user text-2xl"></i>
                ) : (
                  <div className="w-[47px] h-[47px] justify-center items-center flex border-2 rounded-full bg-customBlue text-2xl text-white">
                    {selectedCharacter[0]}
                  </div>
                )}
              </div>
              <div
                className={`mx-[12px] p-4 max-w-[295px] ${
                  message.role === "user" ? "" : "bg-[#CECECD2E]"
                } rounded-md ${
                  message.role === "user" ? "justify-end" : "justify-start"
                } border`}
              >
                <div>{message.content}</div>
              </div>
            </div>
          ))}
          {isMessagesLoading && (
            <div className="p-4 shadow-lg my-2 max-w-[60%] flex flex-row gap-2 rounded-lg bg-gray-300 text-black self-start">
              <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"></div>
              <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-200"></div>
              <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-400"></div>
            </div>
          )}
        </div>

        {/* Input Box */}
        <div className="w-full px-[22px] h-[56px] mb-[84px]">
          <div className="h-full justify-center items-center flex bg-[#F7F9FB] rounded-xl ps-[20px]">
            <input
              className="text-lg flex-grow bg-[#F7F9FB] focus:outline-none h-full"
              type="text"
              placeholder="Type a message..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              className="p-4 text-gray-500 hover:text-gray-700"
              onClick={handleSend}
              aria-label="Handle send"
            >
              <PaperPlaneRight width="20px" height="20px" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
