import { useContext, useEffect, useState } from "react";
import MbTitleBar from "../Common/MbTitleBar";
import Logo1 from "../../Assets/logo-1.webp";
import { dislikePost, getPosts, likePost } from "./CommunityUtils";
import { use } from "react";
import { get_user_id } from "../UserAuthentication/AuthUtils";

import Post from "./Post";
import AddPost from "./AddPost";
import LoadingPost from "./LoadingPost";
import { UserContext } from "../../App";
import ErrorUserNotSignedIn from "../Order/ErrorUserNotSignedIn";

const CommunityPage = ({ setSelectedTab }) => {
  const [posts, setPosts] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showAddPost, setShowAddPost] = useState(false);
  const user_id = get_user_id();
  const { isUserLoggedIn } = useContext(UserContext);
  useEffect(() => {
    setSelectedTab("community");
  }, [setSelectedTab]);

  useEffect(() => {
    getPosts({ limit, offset, setPosts, setLimit, setOffset, setIsLoading });
  }, []);

  return (
    <div className="w-full flex flex-col h-full ">
      {showAddPost && <AddPost setShowAddPost={setShowAddPost} />}
      <>
        <div className="hidden md:flex w-full text-2xl font-semibold text-customBlue h-[84px] items-center ps-[35px] pt-[22px] pb-[33px] border-b">
          Community
        </div>
        <div className="flex md:hidden w-full">
          <MbTitleBar />
        </div>
        <div className="w-full flex-grow overflow-y-scroll  mb-10">
          {isUserLoggedIn ? (
            <div className="flex mt-5 px-6 md:px-[32px]  ">
              <div className="w-full bg-customBlue h-[83px] md:h-[148px] rounded-lg flex justify-between">
                <div className="w-1/2 flex justify-start items-center ps-4 md:ps-11">
                  <img
                    src={Logo1}
                    alt="Logo"
                    className="w-[46px] h-[51px] md:h-[85px] md:w-[78px]"
                  />
                  <div className="flex flex-col md:ms-6 ms-4">
                    <div className="text-white text-xl md:text-4xl mb-1">
                      ReadNest
                    </div>
                    <div className="text-white text-xs md:text-sm">
                      Readkar Community
                    </div>
                  </div>
                </div>
                <div className="w-1/2 flex justify-end items-center pe-4 md:pe-11">
                  <div
                    className="bg-white h-[43px] w-[112px] md:w-[155px] rounded-lg justify-center items-center flex cursor-pointer"
                    onClick={() => setShowAddPost(true)}
                  >
                    <i className="fa-solid fa-plus text-customBlue text-xs md:text-base" />
                    <div className="text-customBlue text-xs md:text-sm font-semibold ms-[10px]">
                      Create Post
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="w-full flex flex-col mt-6 md:mt-11 px-[23px] md:px-8 overflow-y-auto">
            {isLoading ? (
              <>
                <LoadingPost />
              </>
            ) : (
              <>
                {posts.map((item, index) => (
                  <Post key={index} postData={item} user_id={user_id} />
                ))}
              </>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default CommunityPage;
