import { signupHandler } from "../Utilities/FetchUtils";

export const isValidPassword = (password) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return regex.test(password) && password.length >= 8;
};

export const isValidEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const handleSignup = async ({
  signupRequest,
  setIsLoading,
  setShowSuccessModal,
  setErrorMessage,
}) => {
  try {
    const { response, error } = await signupHandler(signupRequest);
    if (response) {
      setShowSuccessModal(true);
    } else {
      if (error.status === 409) {
        setErrorMessage("Email Already Exists");
      } else {
        setErrorMessage(`Password must be at least 8 characters., 
          Must have an uppercase letter., 
          Must have a lowercase letter., 
          Must have a number., 
          Must have a special character.`);
      }
    }
  } catch (error) {
  } finally {
    setIsLoading(false);
  }
};
