import React, { useContext, useEffect, useRef } from "react";
import { SSORequest } from "../Serializers/SerializerModels";
import { fetchPostDataNoAuth } from "../Utilities/FetchUtils";
import { ssoLogin } from "./LoginUtils";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import { verifyLogin } from "./AuthUtils";
import { jwtDecode } from "jwt-decode";

const GoogleLogin = () => {
  const googleInitialized = useRef(false);
  const { setIsUserLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const userLoggedIn = async () => {
      try {
        const loggedIn = await verifyLogin();
        if (loggedIn) {
          return;
        }

        // Check if Google API is available after script loads
        const checkGoogleLoaded = () => {
          if (window.google && window.google.accounts) {
            initializeGoogleSignIn(); // Initialize Google Sign-In
          } else {
            console.error(
              "Google API not loaded properly. Retrying in 500ms..."
            );
            setTimeout(checkGoogleLoaded, 500); // Retry after 500ms
          }
        };

        // Check if script is already loaded
        if (googleInitialized.current) {
          return;
        }

        checkGoogleLoaded(); // Start checking if Google API is loaded
      } catch (error) {
        console.error("❌ Error during login check:", error);
      }
    };

    userLoggedIn();
  }, []);

  // Initialize Google Sign-In and One-Tap
  const initializeGoogleSignIn = () => {
    if (googleInitialized.current) {
      console.log("Google Sign-In already initialized.");
      return;
    }

    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    console.log("Google Client ID:", clientId);

    if (!clientId) {
      console.error("❌ Missing Google Client ID.");
      return;
    }

    // Initialize Google One-Tap Login
    window.google.accounts.id.initialize({
      client_id: clientId,
      callback: handleCredentialResponse,
      auto_select: true, // Auto-login returning users
      itp_support: true, // Tracking prevention support
    });

    // Show One-Tap prompt
    window.google.accounts.id.prompt();
    googleInitialized.current = true;
  };

  // Handle Google Login response
  const handleCredentialResponse = async (response) => {
    const idToken = response.credential;
    console.log("Google ID Token:", idToken);

    try {
      // Decode the idToken to get user profile info
      const decodedUser = jwtDecode(idToken);
      // Create SSORequest object with all required user info
      const ssoRequestData = new SSORequest({
        idToken: idToken,
        email: decodedUser.email,
        name: decodedUser.name,
        picture: decodedUser.picture || "",
        invite_code: null, // Pass invite_code if applicable
      });


      // Send the token and user data to the backend
      const result = await fetchPostDataNoAuth(
        "user/sso_callback",
        ssoRequestData
      );


      if (result?.response) {
        if (result?.response) {
          // Correctly pass the response to ssoLogin
          ssoLogin({ response: result.response, navigate, setIsUserLoggedIn });
        }
      } else {
        console.error("❌ Login failed:", result?.detail || result.error);
      }
    } catch (err) {
      console.error("❌ Error during login:", err);
    }
  };

  return (
 <></>
  );
};

export default GoogleLogin;
