import React, { useEffect, useState } from "react";
import UserInfoComponent from "./UserInfo";
import SubscriptionComponent from "./SubscriptionComponent";
import { getAddress } from "../Order/AddressUtils";
import AddAddressComponent from "../Order/AddAddressComponent";
import MbTitleBar from "../Common/MbTitleBar";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import SupportModal from "../Support/SupportComponent";

const UserProfileComponent = ({ setSelectedTab }) => {
  const location = useLocation();
  const param = queryString.parse(location.search);
  const defaultTab = param.tab === "subscription" ? "subscription" : "profile";
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [showAddaddressModal, setShowAddaddressModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState([]);
  useEffect(() => {
    getAddress({ setAddress, setIsLoading });
  }, []);

  const tabs = [
    {
      name: "Profile",
      component: (
        <UserInfoComponent
          address={address}
          setShowAddaddressModal={setShowAddaddressModal}
        />
      ),
      id: "profile",
    },
    {
      name: "Subscription",
      component: <SubscriptionComponent />,
      id: "subscription",
    },
  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab.id);
  };

  useEffect(() => {
    setSelectedTab("profile");
  }, []);
  return (
    <>
      <div className="flex flex-col h-full bg-white overflow-hidden  w-full  ">
        <div className="hidden md:flex w-full h-full flex-col">
          <DkProfile
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            address={address}
            setShowAddaddressModal={setShowAddaddressModal}
          />
        </div>
        <div className="md:hidden flex w-full h-full flex-col">
          <MbProfile
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            address={address}
            setShowAddaddressModal={setShowAddaddressModal}
          />
        </div>
      </div>

      {showAddaddressModal && (
        <>
          <div className="hidden md:flex">
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="fixed  inset-y-0 right-0 bg-white shadow-xl w-[568px] h-full rounded-l-lg ">
                <AddAddressComponent
                  onClose={() => setShowAddaddressModal(false)}
                  address={address}
                />
              </div>
            </div>
          </div>
          <div className="md:hidden flex">
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="fixed bottom-0  flex-col bg-white   z-50 w-full   h-10/12 min-h-[10/12] max-h-[11/12]   rounded-t-2xl ">
                <AddAddressComponent
                  onClose={() => setShowAddaddressModal(false)}
                  address={address}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserProfileComponent;

const DkProfile = ({ tabs, activeTab, setActiveTab }) => {
  const [showSupportModal, setShowSupportModal] = useState(false);
  return (
    <>
      {showSupportModal && (
        <SupportModal
          setShowSupportModal={setShowSupportModal}
          onClose={() => setShowSupportModal(false)}
        />
      )}
      {/* Title */}
      <div className=" flex flex-row w-full h-[84px] px-[35px] pt-[22px] pb-[12px] border-b border-gray-200 ">
        <div className="w-1/2 ">
          <h1 className="text-2xl font-semibold ">Profile</h1>
        </div>
      </div>

      {/* tabs */}
      <div className="flex flex-col w-full px-[35px] pt-[18px] pb-[12px]">
        <div className="w-full text-end flex  font-semibold justify-end mb-4">
          <div
            className=" flex justify-end items-center text-sm"
            onClick={() => setShowSupportModal(true)}
          >
            <i className="fa fa-headset mr-1 fa-sm cursor-pointer" />
            {"Support "}
          </div>
        </div>
        <div className="w-full grid grid-cols-2 rounded rounded-t-lg border border-gray-200 h-[48px]">
          {tabs.map((tab) => (
            <div
              className={` w-full flex justify-center items-center  cursor-pointer h-[48px] text-xl font-semibold py-2 ${
                activeTab === tab.id ? " border-b-2 border-customYellow" : ""
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.name}
            </div>
          ))}
        </div>
      </div>
      {/*  */}
      <>
        <div className="w-full overflow-auto">
          {tabs.find((tab) => tab.id === activeTab).component}
        </div>
      </>
    </>
  );
};

const MbProfile = ({ tabs, activeTab, setActiveTab }) => {
  const [showSupportModal, setShowSupportModal] = useState(false);
  return (
    <>
      <MbTitleBar />

      {showSupportModal && (
        <SupportModal
          setShowSupportModal={setShowSupportModal}
          onClose={() => setShowSupportModal(false)}
        />
      )}
      {/* tabs */}
      <div className="flex flex-col w-full px-5 pt-[18px] pb-[12px]">
        <div className="w-full text-end flex  font-semibold justify-end">
          <div
            className=" flex justify-end items-center text-sm"
            onClick={() => setShowSupportModal(true)}
          >
            <i className="fa fa-headset mr-1 fa-sm cursor-pointer" />
            {"Support "}
          </div>
        </div>
        <div className="w-full grid grid-cols-2  h-[48px]">
          {tabs.map((tab) => (
            <div
              className={` w-full flex justify-center items-center  cursor-pointer h-[48px] text-xs font-semibold  py-2 ${
                activeTab === tab.id
                  ? " border-b-2 border-customBlue text-customBlue"
                  : " text-gray-500"
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.name}
            </div>
          ))}
        </div>
      </div>
      {/*  */}
      <>{tabs.find((tab) => tab.id === activeTab).component}</>
    </>
  );
};
