import { useContext } from "react";
import Logo1 from "../../Assets/logo-1.webp";
import { UserContext } from "../../App";
import { useNavigate } from "react-router-dom";

const MbTitleBar = () => {
  const { isUserLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();
  return (
    <div className="flex  w-full mt-[24px] mb-2 ">
      {" "}
      <div className="px-[24px] h-[44px]  w-full flex flex-row justify-around ">
        <div className="w-1/2 flex justify-start">
          <a href="/">
            <img src={Logo1} alt="Logo" className="w-[42px] h-[42px]" />
          </a>
        </div>
        <div className=" w-1/2 flex justify-end">
          {isUserLoggedIn ? (
            <div className="w-[42px] h-[42px] justify-center items-center flex border-2 rounded-full">
              <a href="/profile">
                <i className="fa-solid fa-user text-2xl text-customBlue" />
              </a>
            </div>
          ) : (
            <>
              <div className="flex space-x-4">
                <div
                  className="bg-customBlue text-center align-items-center text-white px-8 py-3  rounded-full font-bold"
                  onClick={() => navigate("/login")}
                >
                 <p>
                 Sign In
                  </p> 
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MbTitleBar;
