import { RequestBookRequest } from "../Serializers/SerializerModels";
import { fetchPostData } from "../Utilities/FetchUtils";

export const handleSearch = async ({
  title,
  author,
  language,
  setSearchResult,
  setError,
  setIsLoading,
}) => {
  const titleQuery = title ? `"${title}"` : "";
  const authorQuery = author ? `inauthor:${author}` : "";
  const languageQuery = language ? `langRestrict=${language}` : "";
  const query = [titleQuery, authorQuery]
    .filter(Boolean)
    .join(" AND ");

  if (!query) {
    setError("Please enter at least a title or author to search.");
    return;
  }

  setIsLoading(true);
  setError(null);

  try {
    const apiUrl = `https://www.googleapis.com/books/v1/volumes?q=${query}&country=IN&${languageQuery}`;
    const response = await fetch(apiUrl);

    if (!response.ok) {
      throw new Error("Failed to fetch books. Please try again later.");
    }

    const data = await response.json();
    // Client-side filter for mature content
    const filteredBooks = (data.items || []).filter(book => {
      return book.volumeInfo.maturityRating !== 'MATURE';
    });
  

    setSearchResult(filteredBooks);
    if (data.items.length === 0) {
      setError("No books found. Try adjusting your search criteria.");
    }
  } catch (error) {
    setError("Failed to find the book.");
  } finally {
    setIsLoading(false);
  }
};



export const requestBook = async({book,toast,setSuccessModal,setSelectedBook,isUserLoggedIn}) => {

  if( !isUserLoggedIn){
    toast.error("Please login to request a book");
    return;
  }
    const { response, error } = await fetchPostData(
      "books/request_book",
      new RequestBookRequest(book)
    );
    if (response) {
      const data = await response;
      toast.success("Book requested successfully \n Search for your requested book");
      setSelectedBook(data.book_details[0])
      setSuccessModal(true)
    } else {

      toast.error("Something went wrong");
    }
    

  
}