import { useRef } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import j_k_rowling from "../../Assets/Author/j_k_rowling.webp";
import durjoy_datta from "../../Assets/Author/durjoy_dutta.webp";
import j_rr_tolkien from "../../Assets/Author/j-rr-tolkein.webp";
import GeorgeMartin from "../../Assets/Author/george_rr.webp";
import Paulo_c from "../../Assets/Author/paulo_c.webp";
import ravinder_s from "../../Assets/Author/ravinder_s.webp";
import stephen_k from "../../Assets/Author/stephen_k.webp";
import chetan_b from "../../Assets/Author/Chetan_Bhagat.webp";
import dan_brown from "../../Assets/Author/dan_b.webp";
import Khaled_h from "../../Assets/Author/Khaled-Hosseini.webp";
import jeffrey_a from "../../Assets/Author/Jeffery_a.webp";
import colleen_h from "../../Assets/Author/Collene.webp";

import { useNavigate } from "react-router-dom";
const authorList = [
  {
    name: "J.K. Rowling",
    icon: (
      <img
        src={j_k_rowling}
        alt="Fantasy"
        className="w-full  object-cover rounded-full"
      />
    ),
  },
  {
    name: "Durjoy Datta",
    icon: (
      <img
        src={durjoy_datta}
        alt="Fantasy"
        className="w-full h-full object-cover rounded-full"
      />
    ),
  },
  {
    name: "J.R.R. Tolkien",
    icon: (
      <img
        src={j_rr_tolkien}
        alt="Fantasy"
        className="w-full h-full object-cover rounded-full"
      />
    ),
  },
  {
    name: "George R.R. Martin",
    icon: (
      <img
        src={GeorgeMartin}
        alt="Fantasy"
        className="w-full h-full object-cover rounded-full"
      />
    ),
  },
  {
    name: "Paulo Coelho",
    icon: (
      <img
        src={Paulo_c}
        alt="Fantasy"
        className="w-full h-full object-cover rounded-full"
      />
    ),
  },
  {
    name: "Ravinder Singh",
    icon: (
      <img
        src={ravinder_s}
        alt="Fantasy"
        className="w-full h-full object-cover rounded-full"
      />
    ),
  },
  {
    name: "Chetan Bhagat",
    icon: (
      <img
        src={chetan_b}
        alt="Fantasy"
        className="w-full h-full object-cover rounded-full"
      />
    ),
  },
  {
    name: "Dan Brown",
    icon: (
      <img
        src={dan_brown}
        alt="Fantasy"
        className="w-full h-full object-cover rounded-full"
      />
    ),
  },
  {
    name: "Stephen King",
    icon: (
      <img
        src={stephen_k}
        alt="Fantasy"
        className="w-full h-full object-cover rounded-full"
      />
    ),
  },
  {
    name: "Khaled Hosseini",
    icon: (
      <img
        src={Khaled_h}
        alt="Fantasy"
        className="w-full h-full object-cover rounded-full"
      />
    ),
  },
  {
    name: "Jeffrey Archer",
    icon: (
      <img
        src={jeffrey_a}
        alt="Fantasy"
        className="w-full h-full object-cover rounded-full"
      />
    ),
  },
  {
    name: "Colleen Hoover",
    icon: (
      <img
        src={colleen_h}
        alt="Fantasy"
        className="w-full h-full object-cover rounded-full"
      />
    ),
  },
];

const AuthorSlider = () => {
  const sliderRef = useRef(null);
  const navigate = useNavigate();

  const scroll = (offset) => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: offset, behavior: "smooth" });
    }
  };

  return (
    <>
      <div className=" text-xl font-semibold   md:text-2xl  flex w-full justify-start px-6 mt-6">
        {"Browse by Author"}
      </div>
      <div className="relative w-full px-6">
        {/* Left Scroll Button */}
        <button
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-3 shadow-xl rounded-full z-10 hover:scale-110 transition-all duration-300"
          onClick={() => scroll(-200)}
        >
          <FaArrowLeft className="text-l md:text-2xl text-customBlue" />
        </button>

        <div
          ref={sliderRef}
          className="flex gap-6 overflow-x-auto scrollbar-hide py-6 px-4 whitespace-nowrap snap-x snap-mandatory"
        >
          {authorList.map((author, index) => (
            <div
              key={index}
              className="flex flex-col items-center gap-3 snap-center"
              onClick={() => {
                navigate(`/search?query=${author.name}`);
              }}
            >
              <div className="w-[90px] h-[90px] md:w-[110px] md:h-[110px] flex items-center justify-center border-4 rounded-full bg-gradient-to-r  border-customBlue text-white text-3xl md:text-4xl shadow-lg hover:scale-110 transition-transform duration-300 overflow-hidden">
                {typeof author.icon === "string" ? author.icon : author.icon}
              </div>
              <div className="text-center text-sm md:text-l font-semibold text-gray-800 text-wrap">
                {author.name}
              </div>
            </div>
          ))}
        </div>

        {/* Right Scroll Button */}
        <button
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-3 shadow-xl rounded-full z-10 hover:scale-110 transition-all duration-300"
          onClick={() => scroll(200)}
        >
          <FaArrowRight className="text-l md:text-2xl text-customBlue" />
        </button>
      </div>
    </>
  );
};

export default AuthorSlider;
