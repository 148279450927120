import {
  getBookmarksFromLocalStorage,
  getRecommendedBooks,
  handleBookmarkClick,
  handleRemoveBookmark,
} from "./BooksUtil";
import { ReactComponent as OpenBook } from "../../Assets/icons/open_book.svg";
import book_missing from "../../Assets/icons/missing-book.png";
import { useEffect, useState, useRef } from "react";

const MbBookDetailComponent = ({
  book,
  onClose,
  orderBook,
  isBookmarked,
  setOpenBook,
}) => {
  const [bookmarks, setBookmarks] = useState([]);
  const [isBookmarkedVar, setIsBookmarkedvar] = useState(isBookmarked);
  const [showRecommendations, setShowRecommendations] = useState(false);
  const [recommendedBooks, setRecommendedBooks] = useState([]);
  const [recommendedBooks1, setRecommendedBooks1] = useState([]);
  const descriptionRef = useRef(null);

  useEffect(() => {
    setShowRecommendations(false);
    getBookmarksFromLocalStorage(setBookmarks);
    getRecommendedBooks({ book, setRecommendedBooks, setRecommendedBooks1 });
  }, [book]);

  useEffect(() => {
    if (bookmarks.some((b) => b.id === book.id)) {
      setIsBookmarkedvar(true);
    } else {
      setIsBookmarkedvar(false);
    }
  }, [bookmarks, book.id]);

  if (!book) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="fixed bottom-0 flex-col bg-white z-50 w-full h-10/12 min-h-[10/12] rounded-t-2xl max-h-[100vh] overflow-x-auto ">
        {/* Header */}
        <div className="flex flex-row min-h-[56px] shadow-sm items-center mx-[33px]">
          <div className="text-lg font-semibold text-customBlue line-clamp-1 w-11/12 flex justify-start my-[22px]">
            {book.title}
          </div>
          <div className="w-1/12 flex justify-end">
            <button
              className="text-2xl font-bold text-gray-600 hover:text-gray-800"
              onClick={onClose}
            >
              &times;
            </button>
          </div>
        </div>

        {/* Author & Bookmark */}
        <div className="flex flex-row h-[56px] border-b mx-[33px] items-center">
          <div className="text-sm text-customBlue line-clamp-1 w-11/12 flex justify-start">
            By {book.author}
          </div>
          <div className="w-1/12 flex justify-end">
            {isBookmarkedVar ? (
              <i
                className="fa-solid fa-bookmark text-base sm:text-lg md:text-xl text-customBlue cursor-pointer"
                onClick={() => {
                  handleRemoveBookmark(book, setBookmarks);
                  setIsBookmarkedvar(false);
                }}
              />
            ) : (
              <i
                className="fa-solid fa-bookmark text-base sm:text-lg md:text-xl text-gray-400 cursor-pointer"
                onClick={() => {
                  handleBookmarkClick(book);
                  setIsBookmarkedvar(true);
                }}
              />
            )}
          </div>
        </div>

        {/* Book Image */}
        <div className="w-full flex items-center justify-center">
          {book.image_url ? (
            <img
              src={book.image_url}
              alt={book.title}
              className="w-[90px] h-[137px] object-cover my-6"
            />
          ) : (
            <div className="w-[90px] h-[137px] object-cover my-6">
              <img
                src={book_missing}
                alt={book.title}
                className="w-[80px] h-[80px] object-cover my-6"
              />
            </div>
          )}
        </div>

        {/* Book Description */}
        {!showRecommendations && (
          <div
            ref={descriptionRef}
            className="w-full px-[33px] mt-[16px] mb-[px] h-[216px] overflow-y-scroll"
          >
            {book.description || "No description available."}
          </div>
        )}

        <div className="w-full px-[16px] my-[4px] text-center">
          <button
            className="text-customBlue text-sm underline"
            onClick={() => setShowRecommendations(!showRecommendations)}
          >
            {showRecommendations ? "Hide Similar Books" : "Show More Books"}
          </button>
        </div>
        {
          showRecommendations && 
        <div className="w-full px-[33px] mt-[16px] mb-[px] h-[216px] overflow-y-scroll">
          {showRecommendations && recommendedBooks.length > 0 && (
            <div className="w-full my-[16px]">
              <h2 className="text-lg font-semibold text-customBlue mb-2">
                More Books by {book.author}
              </h2>
              <div className="flex overflow-x-scroll space-x-4 pb-2">
                {recommendedBooks.map((recBook) => (
                  <div
                    key={recBook.id}
                    className="flex flex-col items-center min-w-[90px]"
                    onClick={() => setOpenBook(recBook)}
                  >
                    <img
                      src={recBook.image_url || book_missing}
                      alt={recBook.title}
                      className="w-[90px] h-[137px] object-cover rounded-md"
                    />
                    <span className="text-sm text-center mt-1 w-[90px] line-clamp-1">
                      {recBook.title}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {showRecommendations && recommendedBooks1.length > 0 && (
            <div className="w-full  my-[16px]">
              <h2 className="text-lg font-semibold text-customBlue mb-2">
              More books by {book.genre[0]}
              </h2>
              <div className="flex overflow-x-scroll space-x-4 pb-2">
                {recommendedBooks1.map((recBook) => (
                  <div
                    key={recBook.id}
                    className="flex flex-col items-center min-w-[90px]"
                    onClick={() => setOpenBook(recBook)}
                  >
                    <img
                      src={recBook.image_url || book_missing}
                      alt={recBook.title}
                      className="w-[90px] h-[137px] object-cover rounded-md"
                    />
                    <span className="text-sm text-center mt-1 w-[90px] line-clamp-1">
                      {recBook.title}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        }
        {/* Divider */}
        <div className="w-full h-[1px] bg-gray-200 shadow"></div>
        <div className="w-full px-[16px] my-[16px]  ">
          <button
            onClick={() => orderBook({ book, onClose })}
            className="  flex flex-row items-center w-full  px-[16px] bg-customBlue justify-center text-white h-11 rounded-lg"
          >
            <OpenBook width="19" height="19" fill="currentColor" />
            <span className="ml-2">Rent Now</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MbBookDetailComponent;
