function convertText(text) {
  // Replace underscores with spaces and convert to lowercase
  text = text.replace(/_/g, " ").toLowerCase();

  // Convert to title case
  return text.replace(/\b\w/g, (char) => char.toUpperCase());
}

export class LoginResponse {
  constructor({ token }) {
    this.access_token = token.access_token;
    this.refresh_token = token.refresh_token;
  }
}

export class LoginRequest {
  constructor(username, password) {
    this.username = username;
    this.password = password;
  }

  getRequest() {
    return JSON.stringify(this);
  }
}

export class BookModel {
  constructor(data) {
    this.id = data.bd_id;
    this.title = data.bd_name;
    this.author = data.bd_author;
    this.pages = data.bd_details !== undefined ? data.bd_details.pages : "NA";
    this.year =
      data.bd_details !== undefined ? data.bd_details.publication_year : "NA";
    this.genre = data.bd_genre;
    this.description = data.bd_description;
    this.image_url = data.bd_image_url
      ? data.bd_image_url[0]
      : "https://picsum.photos/1024";
  }
}

export class WaitlistModelRequest {
  constructor(data) {
    this.email = data.email;
    this.phone_number = data.phone;
    this.first_name = data.firstName;
    this.last_name = data.lastName;
  }
}

export class SignupRequest {
  constructor(data) {
    this.email = data.email;
    this.password = data.password;
    this.phone_number = data.phone;
    this.first_name = data.firstName;
    this.last_name = data.lastName;
    this.invite_code = data.inviteCode;
  }

  getRequest() {
    return JSON.stringify(this);
  }
}

export class SearchRequest {
  constructor(searchTerm, limit, offset) {
    this.search = searchTerm;
    this.limit = limit;
    this.offset = offset;
  }

  getRequest() {
    return JSON.stringify(this);
  }
}

export class UserObject {
  constructor(data) {
    this.email = data.email;
    this.phone_number = data.phone_number;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.invite_code = data.invitation_code;
  }
}

export class UserSubscription {
  constructor(data) {
    this.id = data.usm_id;
    this.status = data.usm_status;
    this.start_date = data.usm_start_date;
    this.end_date = data.usm_end_date;
    this.book_issued_count = data.usm_book_issued_count;
    this.remaining_book_count = data.usm_remaining_book_count;
    this.allowed_book_at_once = data.usm_allowed_book_at_once;
    this.subscription_type = data.usm_sd_type;
  }
}

export class ChangePasswordRequest {
  constructor(current_password, new_password, confirm_password) {
    this.current_password = current_password;
    this.new_password = new_password;
    this.confirm_password = confirm_password;
  }

  getRequest() {
    return JSON.stringify(this);
  }
}

export class AddressRequest {
  constructor(address) {
    this.name = address.name;
    this.address_line_1 = address.addressLine1;
    this.address_line_2 = address.addressLine2;
    this.city = address.city;
    this.state = address.state;
    this.country = address.country;
    this.pincode = address.pincode;
    this.phone_number = address.phoneNumber;
  }
  getRequest() {
    return JSON.stringify(this);
  }
}

export class AddressModel {
  constructor(address) {
    this.id = address.id;
    this.name = address.name;
    this.addressLine1 = address.address_line_1;
    this.addressLine2 = address.address_line_2;
    this.city = address.city;
    this.state = address.state;
    this.country = address.country;
    this.pincode = address.pincode;
    this.phoneNumber = address.phone_number;
  }
}

export class BookOrderModel {
  constructor({ book_details, order_details }) {
    this.book_id = book_details.bd_id;
    this.book_genre = book_details.bd_genre;
    this.book_name = book_details.bd_name;
    this.book_author = book_details.bd_author;
    this.image_url = book_details.bd_image_url;
    this.order_status = convertText(
      order_details.ubm_status === "TO_BUY_AND_DELIVER"
        ? "TO_BE_DELIVERED"
        : order_details.ubm_status
    );
    this.order_id = order_details.ubm_id;
    this.order_created_at = order_details.ubm_creation_timestamp;
    this.order_updated_at = order_details.ubm_update_timestamp;
  }
}

export class CancelOrderRequest {
  constructor(order_id) {
    this.ubm_id = order_id;
  }
  getRequest() {
    return JSON.stringify(this);
  }
}

export class OrderRequest {
  constructor(book_id, address_id) {
    this.book_id = book_id;
    this.address_id = address_id;
  }
  getRequest() {
    return JSON.stringify(this);
  }
}

export class Subscriptions {
  constructor(data) {
    this.subscription_id = data.sd_id;
    this.subscription_type = data.sd_type;
    this.subscription_status = data.sd_status;
    this.subscription_allowed_book_count = data.sd_allowed_book_count;
    this.subscription_offer_on_book = data.sd_offer_allowed_book_count;
    this.subscription_offer_book_count = data.sd_offer_allowed_book_count_value;
    this.subscription_allowed_book_at_once = data.sd_allowed_book_at_once;
    this.subscription_price = data.sd_price;
    this.subscription_validity = data.sd_validity;
    this.subscription_name = data.sd_name;
    this.subscription_description = data.sd_details["Text"];
    this.subscription_free_delivery = data.sd_free_delivery || true;
    this.priority = data.sd_priority || 0;
  }
}

export class SubscribeRequest {
  constructor(subscription_id) {
    this.sd_id = subscription_id;
  }

  getRequest() {
    return JSON.stringify(this);
  }
}

export class ChatMessageRequest {
  constructor(data) {
    this.chat_message = data.message;
    this.chat_character = data.character;
    this.chat_source = data.source;
    this.chat_session_id = data.chat_session_id;
  }

  getRequest() {
    return JSON.stringify(this);
  }
}

export class ChatsDetailModel {
  constructor(data) {
    const id_split = data.chat_id.split("_");
    this.character = id_split[2];
    this.source = id_split[1];
  }
}

export class ForgetPasswordRequest {
  constructor(data) {
    this.new_password = data.newPassword;
    this.confirm_password = data.confirmPassword;
    this.reset_token = data.resetToken;
  }

  getRequest() {
    return JSON.stringify(this);
  }
}

export class RequestBookRequest {
  constructor(book) {
    this.book_id = book.id;
  }
  getRequest() {
    return JSON.stringify(this);
  }
}

export class DeliveryObjectModel {
  constructor(delivery_object) {
    this.order_id = delivery_object.ubm_id;
    this.order_updated_at = delivery_object.ubm_update_timestamp;

    // Book Details
    this.book_id = delivery_object.bd_id;
    this.book_name = delivery_object.bd_name;
    this.book_condition = delivery_object.be_condition;
    this.book_entity_id = delivery_object.be_id;

    // Delivery Details
    this.delivery_person_name = `${delivery_object.ud_first_name.trim()} ${delivery_object.ud_last_name.trim()}`;
    this.delivery_address = `${delivery_object.ua_address_line_1}, ${delivery_object.ua_address_line_2}, ${delivery_object.ua_city}, ${delivery_object.ua_state} - ${delivery_object.ua_pincode}`;
    this.delivery_city = delivery_object.ua_city;
    this.delivery_state = delivery_object.ua_state;
    this.delivery_pincode = delivery_object.ua_pincode;
    this.delivery_phone_number = delivery_object.ua_phone_number;
  }
}

export class BlogModel {
  constructor(blog) {
    this.title = blog.bd_title;
    this.author = blog.bd_author;
    this.title_id = blog.bd_title_id;
    this.description = blog.bd_description;
    this.date = blog.bd_updation_timestamp;
    this.image = blog.bd_images_url[0];
  }
}

export class PostModel {
  constructor(post) {
    this.id = post.id;
    this.title = post.title;
    this.description = post.post;
    this.timestamp = post.timestamp;
    this.like = post.likes;
    this.dislike = post.dislikes;
    this.comment_count = post.comment_count;
    this.username = post.username;
    this.created_by = post.created_by;
  }
}
export class CommentModel {
  constructor(comment) {
    this.id = comment.id;
    this.comment = comment.comment;
    this.timestamp = comment.timestamp;
    this.like = comment.likes;
    this.dislike = comment.dislikes;
    this.username = comment.username;
    this.created_by = comment.created_by;
  }
}

export class CommentRequest {
  constructor(data) {
    this.comment = data.comment;
    this.post_id = data.post_id;
  }

  getRequest() {
    return JSON.stringify(this);
  }
}

export class PostRequest {
  constructor(data) {
    this.title = data.title;
    this.post = data.post;
  }

  getRequest() {
    return JSON.stringify(this);
  }
}

export class BlogRequest {
  constructor(data = {}) {
    this.title = data.title || "";
    this.description = data.description || "";
    this.files = data.files || [];
  }

  getRequest() {
    const formData = new FormData();

    // Append scalar fields
    formData.append("bd_title", this.title);
    formData.append("bd_description", this.description);

    // Append files if available
    if (this.files.length > 0) {
      this.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
    }

    return formData;
  }
}

export class SSORequest {
  constructor({ idToken, email, name, picture, invite_code = null }) {
    this.user = {
      idToken: idToken,
      email: email,
      name: name,
      picture: picture,
      invite_code: invite_code,
    };
  }

  getRequest() {
    return JSON.stringify(this);
  }
}
