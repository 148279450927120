import { verifyLogin, handleToken } from "./AuthUtils";
import { LoginRequest, LoginResponse } from "../Serializers/SerializerModels";
import { fetchGetDataNoAuth, loginHandler } from "../Utilities/FetchUtils";
import { getBookmarks } from "../Books/BooksUtil";

export const handleLogin = async ({
  username,
  password,
  setErrorMessage,
  navigate,
  setIsUserLoggedIn
}) => {
  var { response, error } = await loginHandler(
    new LoginRequest(username, password)
  );

  if (response) {
    setIsUserLoggedIn(true);
    handleToken(new LoginResponse(response));
    navigate("/home");
  } else {
    if (error.status === 422 || error.status === 401) {
      let err = await error.json()
      setErrorMessage(err.detail);
    } else {
      setErrorMessage("something went wrong. please try again");
    }
  }
};
export const ssoVerify = async ({ token, navigate, setIsUserLoggedIn }) => {
  if (token) {
    const { response, error } = await fetchGetDataNoAuth(
      `user/sso_access?token=${token}`
    );
    if (response) {
      setIsUserLoggedIn(true);
      handleToken(new LoginResponse(response));
      navigate("/home");
    } else {
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  }
};
export const ssoLogin = async ({ response, navigate, setIsUserLoggedIn }) => {
  if (response) {
    setIsUserLoggedIn(true);
    handleToken(new LoginResponse(response));
    navigate("/home");
    window.location.reload();
  } else {
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  }
};


export const handleUsernameChange = ({ e, setUsername, setErrorMessage }) => {
  const newUsername = e.target.value;
  setErrorMessage("");
  setUsername(newUsername);
};

export const handlePasswordChange = ({ e, setPassword, setErrorMessage }) => {
  const newPassword = e.target.value;
  setPassword(newPassword);
  setErrorMessage("");
};

export const verifyUserLogin = async ({ navigate }) => {
  const loggedIn = await verifyLogin();
  if (!loggedIn) {
    navigate("/login");
  }
  else{
    getBookmarks();
  }
  return true;
};
