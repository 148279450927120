import MbBookListsComponent from "../Books/MbBookListsComponent";
import DidYouKnow from "../Extras/DidYouKnowComponent";
// import Logo2 from "../../Assets/logo-2.png";
import MbTitleBar from "../Common/MbTitleBar";
import GenreSlider from "./GenreSlider";
import AuthorSlider from "./AuthorSlider";

const HomeMb = ({
  trendingBooks,
  genre1Books,
  genre2Books,
  genre3Books,
  genre4Books,
  genre5Books,
  scienceBooks,
  romanceBooks,
  selfHelpBooks,
  setOpenBook,
  handleSearchRedirect,
  handleKeyPress,
  searchTerm,
  setSearchTerm,
  orderBook,
  onClose,
}) => {
  return (
    <>
      <MbTitleBar />
      <div className="w-full h-full overflow-y-scroll mb-7">
        <div className="flex flex-row w-full h-[84px] justify-center pt-[22px] pb-[12px] ">
          <div className="w-full px-4 md:px-8 lg:px-24">
            <div className="w-full h-[48px] border rounded-3xl justify-start px-4 items-center flex">
              <input
                className="text-lg flex-grow placeholder-gray-500 focus:outline-none"
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <button
                className="p-4 text-gray-500 hover:text-gray-700"
                onClick={() => handleSearchRedirect(searchTerm)}
                aria-label="Search books"
              >
                <i className="fa-solid fa-search" />
              </button>
            </div>
          </div>
        </div>
        <GenreSlider />
        <MbBookListsComponent
          title="New Additions"
          books={trendingBooks}
          setOpenBook={setOpenBook}
          orderBook={orderBook}
          onClose={onClose}
        />

        <div className="w-full mt-[30px] px-4 md:px-8 lg:px-24 p-5 rounded-xl">
          <DidYouKnow />
        </div>
        <MbBookListsComponent
          title="Children's Books"
          books={genre3Books}
          setOpenBook={setOpenBook}
          orderBook={orderBook}
          onClose={onClose}
        />
        <AuthorSlider/>
        <MbBookListsComponent
          title="Trending"
          books={trendingBooks}
          setOpenBook={setOpenBook}
          orderBook={orderBook}
          onClose={onClose}
        />
        <MbBookListsComponent
          title="Self-Help"
          books={selfHelpBooks}
          setOpenBook={setOpenBook}
          orderBook={orderBook}
          onClose={onClose}
        />
        <MbBookListsComponent
          title="Business & Investment"
          books={genre5Books}
          setOpenBook={setOpenBook}
          orderBook={orderBook}
          onClose={onClose}
        />
        <MbBookListsComponent
          title="Adventure"
          books={genre4Books}
          setOpenBook={setOpenBook}
          orderBook={orderBook}
          onClose={onClose}
        />

        <MbBookListsComponent
          title="Fiction"
          books={genre1Books}
          setOpenBook={setOpenBook}
          orderBook={orderBook}
          onClose={onClose}
        />
        <MbBookListsComponent
          title="Fantasy"
          books={genre2Books}
          setOpenBook={setOpenBook}
          orderBook={orderBook}
          onClose={onClose}
        />
        <MbBookListsComponent
          title="Science"
          books={scienceBooks}
          setOpenBook={setOpenBook}
          orderBook={orderBook}
          onClose={onClose}
        />
        <MbBookListsComponent
          title="Romance"
          books={romanceBooks}
          setOpenBook={setOpenBook}
          orderBook={orderBook}
          onClose={onClose}
        />
      </div>
    </>
  );
};

export default HomeMb;
