import React from 'react';
import { motion } from 'framer-motion';
import logo_1 from "../../Assets/logo-1.webp";

const Loading = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-white flex-col">
      <motion.img
        src={logo_1}
        alt="ReadKar Logo 1"
        className="w-32 h-32 mb-4"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, repeat: Infinity, repeatType: "reverse" }}
      />
    </div>
  );
};

export default Loading;
