import { useRef } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import adventure from "../../Assets/Genre/Adventure.webp";
import mystery from "../../Assets/Genre/Mystery.webp";
import wizard from "../../Assets/Genre/Wizard.webp";
import astronaut from "../../Assets/Genre/astronaut.webp";
import thriller from "../../Assets/Genre/Thriller.webp";
import romance from "../../Assets/Genre/Romance.webp";
import knight from "../../Assets/Genre/Knight.webp";
import Horror from "../../Assets/Genre/Horror.webp";
import youngAdult from "../../Assets/Genre/YoungAdult.webp";
import selfHelp from "../../Assets/Genre/selfHelp.webp";
import Children from "../../Assets/Genre/children.webp";
import { useNavigate } from "react-router-dom";

const bookGenres = [
  {
    name: "Children",
    icon: (
      <img
        src={Children}
        alt="Children"
        className="w-9/12 object-cover rounded-full"
      />
    ),
  },
  {
    name: "Fantasy",
    icon: (
      <img
        src={wizard}
        alt="Fantasy"
        className="w-1/2  object-cover rounded-full"
      />
    ),
  },
  {
    name: "Science Fiction",
    icon: (
      <img
        src={astronaut}
        alt="Science Fiction"
        className="w-4/5  object-cover rounded-full"
      />
    ),
  },
  {
    name: "Mystery",
    icon: (
      <img
        src={mystery}
        alt="Mystery"
        className="w-1/2  object-cover rounded-full"
      />
    ),
  },
  {
    name: "Thriller",
    icon: (
      <img
        src={thriller}
        alt="Thriller"
        className="w-1/2  object-cover rounded-full"
      />
    ),
  },
  {
    name: "Romance",
    icon: (
      <img
        src={romance}
        alt="Romance"
        className="w-full object-cover rounded-full"
      />
    ),
  },
  {
    name: "Historical Fiction",
    icon: (
      <img
        src={knight}
        alt="Historical Fiction"
        className="w-1/2  object-cover rounded-full"
      />
    ),
  },
  {
    name: "Horror",
    icon: (
      <img
        src={Horror}
        alt="Horror"
        className="w-11/12  object-cover rounded-full"
      />
    ),
  },
  {
    name: "Young Adult",
    icon: (
      <img
        src={youngAdult}
        alt="Young Adult"
        className="w-1/2  object-cover rounded-full"
      />
    ),
  },
  {
    name: "Self-Help",
    icon: (
      <img
        src={selfHelp}
        alt="Self-Help"
        className="w-4/12  object-cover rounded-full"
      />
    ),
  },
  { name: "Biography", icon: "📜" },
  {
    name: "Adventure",
    icon: (
      <img
        src={adventure}
        alt="Adventure"
        className="w-1/2  object-cover rounded-full"
      />
    ),
  },
];

const GenreSlider = () => {
  const sliderRef = useRef(null);
  const navigate = useNavigate();

  const scroll = (offset) => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: offset, behavior: "smooth" });
    }
  };

  return (
    <>
      <div className=" text-xl font-semibold   md:text-2xl  flex w-full justify-start px-6 mt-4">
        {"Browse by Genre"}
      </div>

      <div className="relative w-full ">
        {/* Left Scroll Button */}
        <button
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-3 shadow-xl rounded-full z-10 hover:scale-110 transition-all duration-300"
          onClick={() => scroll(-200)}
        >
          <FaArrowLeft className="text-l md:text-2xl text-customBlue" />
        </button>

        <div
          ref={sliderRef}
          className="flex gap-6 overflow-x-auto scrollbar-hide py-6 px-10 md:px-12 whitespace-nowrap snap-x snap-mandatory"
        >
          {bookGenres.map((genre, index) => (
            <div
              key={index}
              className="flex flex-col items-center gap-3 snap-center"
              onClick={() => {
                navigate(`/search?query=${genre.name}`);
              }}
            >
              <div className="w-[70px] h-[70px] md:w-[110px] md:h-[110px] flex items-center justify-center border-4 rounded-full bg-gradient-to-r  border-customBlue text-white text-3xl md:text-4xl shadow-lg hover:scale-125 transition-transform duration-300 overflow-hidden">
                {typeof genre.icon === "string" ? genre.icon : genre.icon}
              </div>
              <div className="text-center text-sm md:text-lg font-semibold text-gray-800">
                {genre.name}
              </div>
            </div>
          ))}
        </div>

        {/* Right Scroll Button */}
        <button
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-3 shadow-xl rounded-full z-10 hover:scale-110 transition-all duration-300"
          onClick={() => scroll(200)}
        >
          <FaArrowRight className="text-l md:text-2xl text-customBlue" />
        </button>
      </div>
    </>
  );
};

export default GenreSlider;
