import book_illustration from "../../Assets/book_illu.png"; // Use a different illustration
import AnimatedWordCycle from "./AnimatedWordCycle";
const MBCard1 = ({ inviteCode, setInviteCode, handleClick }) => {
  return (
    <div className="w-full bg-CustomYellow">
      <div className=" w-full  item-start justify-center flex">
        <img
          src={book_illustration}
          alt="Book Illustration"
          className="w-10/12  transform translate-y-[20%] -rotate-12"
        />
      </div>

      <div className="relative flex flex-col md:flex-row justify-center items-center bg-white w-full rounded-t-2xl shadow-lg">
        <div className="relative z-10 w-full md:w-1/2 flex flex-col px-6 py-4">
          <div className="flex items-center mb-4 md:mb-6"></div>
          <div className="flex flex-col mb-4 md:mb-6">
            <div>
              <AnimatedWordCycle />
            </div>
            <div>
              <p className="text-gray-600 ">
                Rent your favourite books, and engage in meaningful
                conversations with the characters that inspire you.
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-4 md:mb-6">
            <div>
              <button
                className="mt-4 bg-customBlue hover:bg-customBlue text-white font-bold py-2 px-4 rounded-md"
                onClick={() => handleClick()}
              >
                Sign Up Now <i className="fa fa-arrow-right"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MBCard1;
