import React, { useState } from "react";
import { fetchGetDataNoAuth } from "../Utilities/FetchUtils";

const InviteTokenModal = ({ onContinue, inviteCode, setShowInviteModal,ssoFlow, onGoogleSSOClick,setIsParentLoading  }) => {
  const [code, setCode] = useState(inviteCode);
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tokenErrorMessage, setTokenErrorMessage] = useState("");

  const verifyInviteCode = async () => {
    setIsLoading(true);
    try {
      if (code) {
        const { response, error } = await fetchGetDataNoAuth(
          `user/verify_invite?code=${code}`
        );
        if (response) {
          setIsVerified(true);
        } else {
          setTokenErrorMessage("Not A Valid Invite Code!");
        }
      }
    } catch (error) {
      setTokenErrorMessage("Not A Valid Invite Code!");
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleContinue = () => {
    setIsParentLoading(true);
    setShowInviteModal(false);
    if (ssoFlow) {
      onGoogleSSOClick(code);
    }
    else{
      onContinue(code);
    }
  };


  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-2xl shadow-lg w-full max-w-md text-center">
        <h2 className="text-xl font-semibold mb-4">
          Have an invite code or promo code?
        </h2>
        <input
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          className="w-full border border-gray-300 rounded px-4 py-2 mb-4 focus:outline-none focus:ring-2 focus:ring-black"
          placeholder="Enter code"
        />
        {tokenErrorMessage && (
          <p className="text-red-500 mb-4">{tokenErrorMessage}</p>
        )}

        {isLoading ? (
          <>
            <button className="w-full bg-gray-500 text-white py-2 rounded mb-2">
              Verifying...
            </button>
          </>
        ) : (
          <>
            <button
              onClick={isVerified ? handleContinue : verifyInviteCode}
              className="w-full bg-customBlue text-white py-2 rounded mb-2 hover:bg-gray-900 transition"
            >
              {isVerified ? "Continue" : "Verify"}
            </button>
          </>
        )}

        <button
          onClick={handleContinue}
          className="w-full text-custombg-customBlue underline hover:text-gray-700"
        >
          Skip
        </button>
      </div>
    </div>
  );
};

export default InviteTokenModal;
