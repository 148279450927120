import React, { useEffect, useMemo, useState, useContext } from "react";
import { cancelOrder, getMyBooks, returnOrder } from "./MyBooksUtils";
import Loading from "../Common/Loading";
import { CancelOrderRequest } from "../Serializers/SerializerModels";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns"; // date formatting
import MbMyBooks from "./MbMyBooks";
import DkMyBooks from "./DkMyBooks";
import CancelConfirmModal from "./CancelConfirmModal";
import { UserContext } from "../../App"; // Import UserContext
import confused_dog_illustration from "../../Assets/confused-dog.webp";
import MbTitleBar from "../Common/MbTitleBar";

const MyBooksComponent = ({ setSelectedTab }) => {
  const { isUserLoggedIn } = useContext(UserContext); // Check user login status
  const [myBooks, setMyBooks] = useState([]);
  const in_process = ["Dispatched", "To Be Delivered"];
  const books_with_me = ["Delivered", "To Be Picked Up"];
  const recent_order = ["Cancelled", "Picked Up", "Returned"];
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [cancelBook, setCancelBook] = useState(null);

  const categorizedBooks = useMemo(() => {
    if (!myBooks || myBooks.length === 0)
      return { in_process: [], books_with_me: [], recent_order: [] };

    // Categorize books
    return myBooks.reduce(
      (acc, book) => {
        const { order_status } = book;
        if (in_process.includes(order_status)) {
          acc.in_process.push(book);
        } else if (books_with_me.includes(order_status)) {
          acc.books_with_me.push(book);
        } else if (recent_order.includes(order_status)) {
          acc.recent_order.push(book);
        }

        return acc;
      },
      { in_process: [], books_with_me: [], recent_order: [] } // Initial structure
    );
  }, [myBooks]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setSelectedTab("books");
    if (isUserLoggedIn) {
      getMyBooks({ setMyBooks, setIsLoading, setError });
    }
  }, [isUserLoggedIn]);

  const handleCancelOrder = (book) => {
    setCancelBook(book);
    setShowCancelConfirm(true);
  };

  const cancelOrderhandler = (book) => {
    setIsLoading(true);
    cancelOrder(new CancelOrderRequest(book.order_id), toast);
    setTimeout(() => {
      getMyBooks({ setMyBooks, setIsLoading, setError });
    }, 2000);
  };

  const handleReturnOrder = (book) => {
    setIsLoading(true);
    returnOrder(new CancelOrderRequest(book.order_id), toast);
    setTimeout(() => {
      getMyBooks({ setMyBooks, setIsLoading, setError });
    }, 2000);
  };

  const formatDate = (dateString) =>
    format(new Date(dateString), "dd MMM yyyy");

  // If user is not signed in, show sign-in prompt
  if (!isUserLoggedIn) {
    return (
      <>
      
      
        <div className="flex md:hidden">
          <MbTitleBar />
          </div>
      <div className="flex flex-col items-center justify-center h-full w-full p-6">
        <div className="flex flex-col items-center">

          <img
            src={confused_dog_illustration}
            alt="Confused Dog Illustration"
            className="w-1/2"
          />
          <h1 className="text-start mb-4 font-semibold text-2xl text-customBlue">
            Hold on!
          </h1>
          <div className="text-center mb-4">
            You need to sign in to access your books.
          </div>

          <div className="flex justify-center w-full">
            <a
              href="/login"
              className="mt-4 bg-customBlue text-white font-bold py-2 px-4 rounded hover:bg-customBlueDark transition duration-200"
            >
              Sign In
            </a>
          </div>
        </div>
      </div>
      </>
    );
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex md:hidden flex-col w-full h-full ">
            <MbMyBooks
              categorizedBooks={categorizedBooks}
              isLoading={isLoading}
              handleReturnOrder={handleReturnOrder}
              handleCancelOrder={handleCancelOrder}
            />
          </div>
          <div className="hidden md:flex flex-col w-full h-full">
            <DkMyBooks
              categorizedBooks={categorizedBooks}
              isLoading={isLoading}
              handleReturnOrder={handleReturnOrder}
              handleCancelOrder={handleCancelOrder}
            />
          </div>
        </>
      )}
      {showCancelConfirm && (
        <CancelConfirmModal
          setShowCancelConfirmModal={setShowCancelConfirm}
          book={cancelBook}
          cancelOrder={cancelOrderhandler}
        />
      )}
    </>
  );
};

export default MyBooksComponent;
