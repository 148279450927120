import {
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton,
  TelegramShareButton,
  FacebookShareButton,
  WhatsappIcon,
  TwitterIcon,
  EmailIcon,
  TelegramIcon,
  FacebookIcon,
} from "react-share";

const ShareModal = ({ inviteCode, onClose }) => { 
  const invite_url = `https://readkar.com/signup?invite_code=${inviteCode}`;
  const message = `Hey! I’ve started using ReadKar, a book rental service in Pune that delivers physical books straight to your doorstep — quick and hassle-free. 📚✨
  
If you love reading or want to build the habit again, it’s totally worth a look.

Use my invite code *${inviteCode}* (or sign up with my link) — you’ll get 1 extra book free in your first month with the subscription.

Great way to start exploring new reads without buying every book.
                  `

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={onClose}
    >
      <div
        className="bg-white p-6 rounded-lg w-80"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="text-lg font-semibold mb-4 text-center">
          Share your invite
        </h3>

        <div className="flex flex-wrap gap-3 justify-center mb-4">
          <WhatsappShareButton url={invite_url} title={message}>
            <WhatsappIcon size={40} round />
          </WhatsappShareButton>
          <TelegramShareButton url={invite_url} title={message}>
            <TelegramIcon size={40} round />
          </TelegramShareButton>
          <TwitterShareButton url={invite_url} title={message}>
            <TwitterIcon size={40} round />
          </TwitterShareButton>
          <FacebookShareButton url={invite_url} quote={message}>
            <FacebookIcon size={40} round />
          </FacebookShareButton>
          <EmailShareButton
            url={invite_url}
            subject="Check out ReadKar!"
            body={message}
          >
            <EmailIcon size={40} round />
          </EmailShareButton>
          <div
            className=" rounded-full bg-gray-200 hover:bg-gray-300 text-gray-800 py-2 px-4 h-10 w-10 flex justify-center items-center"
            onClick={() => {
              navigator.clipboard.writeText(invite_url);
            }}
          >
            <i className="fa fa-solid fa-clipboard"></i>
          </div>
        </div>

        <button
          onClick={onClose}
          className="w-full mt-2 text-sm bg-gray-200 hover:bg-gray-300 text-gray-800 py-2 px-4 rounded-lg"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ShareModal;
