

const SupportModal = ({ onClose }) => {
  const phoneNumber = "+918956367644";
  const email = "support@readkar.com";

  return (
    <>
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={onClose}>
          <div className="bg-white max-w-md w-full p-6 rounded-2xl shadow-xl" onClick={(e) => e.stopPropagation()}>
            <div className="text-center text-customBlue text-xl font-bold mb-4"> ReadKar Support</div>
            <div className="flex flex-col gap-4">
              <button
                className="flex items-center justify-center gap-2 w-full bg-customYellow text-black p-3 rounded-lg shadow hover:bg-opacity-90"
                onClick={() => window.open(`tel:${phoneNumber}`)}
              >
                📞 Call: {phoneNumber}
              </button>
              <button
                className="flex items-center justify-center gap-2 w-full bg-green-500 text-white p-3 rounded-lg shadow hover:bg-green-600"
                onClick={() => window.open(`https://wa.me/${phoneNumber}`, "_blank")}
              >
                💬 WhatsApp
              </button>
              <button
                className="flex items-center justify-center gap-2 w-full bg-blue-500 text-white p-3 rounded-lg shadow hover:bg-blue-600"
                onClick={() => window.open(`https://t.me/${phoneNumber}`, "_blank")}
              >
                📩 Telegram
              </button>
              <button
                className="flex items-center justify-center gap-2 w-full bg-red-500 text-white p-3 rounded-lg shadow hover:bg-red-600"
                onClick={() => window.open(`mailto:${email}`)}
              >
                ✉️ Email: {email}
              </button>
              <button
                className="w-full bg-gray-300 text-black p-3 rounded-lg shadow hover:bg-gray-400"
                onClick={ onClose}
              >
                ❌ Close
              </button>
            </div>
          </div>
        </div>
    </>
  );
};

export default SupportModal;
